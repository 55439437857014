<template>
  <c-flex
    border="1px solid #F4CC46"
    bg="#F4CC4633"
    p="30px"
    pr="100px"
    rounded="md"
    pos="relative"
  >
    <c-box>
      <c-text font-weight="bold">
        Anda belum mengisi Progress and Tracker untuk Minggu ke-{{week}}
      </c-text>
      <c-text>
        Klik tombol di samping untuk mengisi progress and tracker minggu ke-{{week}}
      </c-text>
    </c-box>
    <c-link
      bg="#F4CC46"
      rounded="full"
      h="50px"
      w="50px"
      d="flex"
      justify-content="center"
      align-items="center"
      as="router-link"
      :to="clickRoute"
      pos="absolute"
      top="50%"
      right="20px"
      mt="-25px"
    >
      <c-image
        :src="require('@/assets/icon-arrow-right-yellow.svg')"
        h="24px"
        w="24px"
      />
    </c-link>
  </c-flex>
</template>

<script >
const __sfc_main = {};
__sfc_main.props = ["week", "clickRoute"];

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  return {};
};

export default __sfc_main;
</script>

<style scoped></style>
