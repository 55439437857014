<template>
  <c-box w="full">
    <c-flex
    :flex-dir="['column', 'row']"
    >
      <c-box flex="1" :mb="['20px', '0']">
        <c-text font-size="2xl">Progress &amp; Tracker</c-text>
        <c-text font-size="2xl" color="brand.500">Balanced Diet</c-text>
      </c-box>
      <c-box w="260px">
        <c-select w="fit-content" v-model="chartMode">
          <option value="graphic">Grafik</option>
          <option value="photo">Foto</option>
        </c-select>
      </c-box>
    </c-flex>
    <c-box mt="30px" w="full">
      <c-flex
        v-if="chartMode === 'graphic'"
        mt="20px"
        h="573px"
        border-color="brand.900"
        border-width="1px"
        px="25px"
        py="20px"
        flex-dir="column"
      >
        <c-flex w="full">
          <c-box flex="1" w="full" />
          <c-box w="260px">
            <c-select w="260px" v-model="chartFilter">
              <option value="weight">Berat Badan</option>
              <option value="waist">Lingkar Pinggang</option>
              <option value="bodyFat">Persen Lemak</option>
              <option value="muscleMass">Massa Otot</option>
              <option value="bodyWater">Cairan Tubuh</option>
              <option value="visceralFat">Lemak Visceral</option>
              <option value="boneMass">Massa Tulang</option>
              <option value="BMR">Body Mass Ratio</option>
            </c-select>
          </c-box>
        </c-flex>
        <c-box mt="70px">
          <Charts key="current-chart" :chart-data="chartData" />
        </c-box>
      </c-flex>
      <c-flex
        v-else
        mt="20px"
        border-color="brand.900"
        border-width="1px"
        px="25px"
        py="20px"
        flex-dir="column"
      >
        <c-box display="flex" pb="20px" overflow-x="auto" max-w="850px">
          <c-box
            v-for="item in progressList"
            :key="item.id"
            d="flex"
            flex-dir="column"
            align-items="center"
          >
            <c-box
              h="270px"
              w="200px"
              bg="gray"
              mx="5px"
              d="flex"
              align-items="center"
              justify-content="center"
            >
              <a
                :href="item.progressPhoto"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img v-chakra :src="item.progressPhoto" />
              </a>
            </c-box>
            <c-box
              mt="30px"
              pos="relative"
              w="full"
              d="flex"
              align-items="center"
              justify-content="center"
              border-top-width="2px"
              border-top-color="#008C8136"
            >
              <c-box
                pos="absolute"
                top="-15px"
                h="30px"
                w="30px"
                border-width="10px"
                border-color="white"
                rounded="full"
                bg="brand.500"
              />
              <c-text mt="20px">
                {{ item.date | formatDate("DD MMM") }}
              </c-text>
            </c-box>
          </c-box>
        </c-box>
      </c-flex>
      <c-flex mt="30px" flex-dir="column">
        <Notice
          v-for="item in emptyProgressList"
          :key="item.id"
          :week="item.week"
          :click-route="{
            name: 'client.progress-tracker.submission',
            params: { date: item.date },
          }"
        />

        <c-flex mt="30px" flex-dir="column">
          <CardProgress
            v-for="item in progressList"
            :key="item.id"
            :id="item.id"
            :date="item.date"
            :weight="item.overview.weight || '-'"
            :waist="item.overview.waist || '-'"
            :bodyFat="item.overview.bodyFat || '-'"
            :muscleMass="item.overview.muscleMass || '-'"
            :bodyWater="item.overview.bodyWater || '-'"
            :visceralFat="item.overview.visceralFat || '-'"
            :boneMass="item.overview.boneMass || '-'"
            :bmr="item.overview.BMR || '-'"
            @click="onDetailClick(item.id)"
          />
        </c-flex>
      </c-flex>
    </c-box>
  </c-box>
</template>

<script >
import Charts from "@/components/chart";
import CardProgress from "@/views/nutritionists/progress-tracker/indikator/card-progress";
import Notice from "@/views/client/progress-tracker/notice";
import { computed, onMounted, ref } from "@vue/composition-api";
import { useActions } from "@/stores";
import { getChartData } from "@/utils/get-chart-data";
import { useRouter } from "vue2-helpers/vue-router";
import { useEqualWatch } from "@/views/client/progress-tracker/forms/helper-mixin";
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const $router = useRouter();
  const progressList = ref([]);
  const emptyProgressList = ref([]);
  const chartFilter = ref("bodyFat");
  const chartMode = ref("graphic");
  const chartData = computed(() => getChartData(progressList.value, chartFilter.value));
  const actions = useActions("clientProgressTracker", ["getProgressData", "getEmptyProgress"]);
  onMounted(async () => {
    console.log("get progress data", Date.now());
    progressList.value = await actions.getProgressData();
    emptyProgressList.value = await actions.getEmptyProgress();
  });
  useEqualWatch(emptyProgressList, progressList => {
    console.log("progressList", progressList);
  });

  function onDetailClick(id) {
    $router.push({
      name: "client.progress-tracker.detail",
      params: {
        progressId: id
      }
    });
  }

  return {
    progressList,
    emptyProgressList,
    chartFilter,
    chartMode,
    chartData,
    onDetailClick
  };
};

__sfc_main.components = Object.assign({
  Charts,
  Notice,
  CardProgress
}, __sfc_main.components);
export default __sfc_main;
</script>

<style scoped></style>
